<template>
  <b-sidebar
    id="sidebar-invoice-add-new-customer"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Customer</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">
          <!-- Email -->
          <b-form-group>
            <label>First Name</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="First Name"
            >
              <b-form-input
                v-model="model.first_name"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Address -->
          <b-form-group>
            <label>Last Name</label>
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="required"
            >
              <b-form-input
                v-model="model.last_name"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Country -->
          <b-form-group>
            <label>Email</label>
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="email"
            >
              <b-form-input
                v-model="model.mail"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Contact -->
          <b-form-group>
            <label>Phone Number</label>
            <validation-provider
              #default="{ errors }"
              name="Phone Number"
              rules="required"
            >
              <b-form-input
                id="phoneNumbre"
                v-model="model.phone_number"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Adress" label-for="nameCountry">
            <b-form-input id="Adress" v-model="model.adress" />
          </b-form-group>

          <b-form-group>
            <label>Birth Date</label>
            <validation-provider
              #default="{ errors }"
              name="Birth Date"
              rules=""
            >
              <flat-pickr
                v
                class="form-control"
                v-model="model.birth_date"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="City" label-for="user-status">
            <validation-provider
              #default="{ errors }"
              name="City"
              rules="required"
            >
              <v-select
                v-model="model.cityId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-status"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <!-- Form Actions -->
      <b-form class="p-2">
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click.prevent="validationForm"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import invoiceStoreModule from "./invoiceStoreModule";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.createCustomer();
        }
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    const model = ref({
      identification_number: "",
      first_name: "",
      last_name: "",
      mail: "",
      phone_numbre: "",
      birth_date: "",
      cityId: "",
      adress: "",
      id_user: 1,
      photo: "photo",
      status: true,
    });
    const cityOptions = ref([]);

    const city = [];

    const parser = JSON.parse(localStorage.getItem("userData"));
    const idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;

    store
      .dispatch("app-user/fetchHelpers", {
        idOffice,
        idRol,
      })
      .then((response) => {
        const { cities } = response.data.data;

        cities.map((data, item) => {
          city.push({ label: data.name, value: data.id, id: item });
        });

        cityOptions.value = city;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      model,
      cityOptions,
      createCustomer: () => {
        model.value.birth_date.length > 0
          ? model.value.birth_date
          : (model.value.birth_date = new Date());

        store
          .dispatch("app-user/addCountry", { model })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: `The cusatomer ${model.value.first_name} ${model.value.last_name} was created correctly`,
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });

            router.push({ name: "apps-invoice-list" });
          })
          .catch((error) => {
            if (error.response.status === 404) {
            }
          });
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
